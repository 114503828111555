import { Lesson, LessonType } from '../../types/Lessons';
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCoach from '../../components/Lessons/AddCoach';
import LessonDaysSelect from '../../components/Lessons/LessonDaysSelect';

function HorsemanshipLessonData(props: {
  lessons: Lesson[];
  setLessons: Function;
  existingLessons: Lesson[];
}) {
  const { lessons, setLessons } = props;

  let lessonTimes: { value: string; display: string }[] = [];
  for (let i = 0; i < 24; i++) {
    let hour = i;
    let morningNight = ' AM';
    if (i == 0) {
      hour = 12;
    } else if (i == 12) {
      morningNight = ' PM';
    } else if (i > 12) {
      hour = i - 12;
      morningNight = ' PM';
    }

    lessonTimes.push(
      { value: `${i}:00`, display: `${hour}:00${morningNight}` },
      { value: `${i}:30`, display: `${hour}:30${morningNight}` }
    );
  }

  return (
    <>
      <Grid container spacing={2} marginTop='1em'>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Start Date'
            required
            type='date'
            onChange={(e) => {
              const newLessons = lessons.map((lesson) => {
                return {
                  ...lesson,
                  startDate: e.target.value,
                };
              });
              setLessons(newLessons);
            }}
            value={lessons[0].startDate}
            name='startDate'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label='End Date'
            required
            type='date'
            onChange={(e) => {
              const newLessons = lessons.map((lesson) => {
                return {
                  ...lesson,
                  endDate: e.target.value,
                };
              });
              setLessons(newLessons);
            }}
            value={lessons[0].endDate}
            name='endDate'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop='1em'>
        <Grid item xs={12} sm={6}>
          <LessonDaysSelect
            required
            selectedItems={lessons[0].activeDays}
            setSelectedItems={(e) => {
              const newLessons = lessons.map((lesson) => {
                return {
                  ...lesson,
                  activeDays: e.target.value,
                };
              });
              setLessons(newLessons);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AddCoach
            coach={lessons[0].coach}
            setCoach={(coach: string) => {
              const newLessons = lessons.map((lesson) => {
                return {
                  ...lesson,
                  coach,
                };
              });
              setLessons(newLessons);
            }}
          />
        </Grid>
      </Grid>

      <Typography variant='h4' marginTop={'0.5em'}>
        Lessons
      </Typography>

      {lessons.map((lesson, index) => {
        if (!lesson) {
          return null;
        }

        return (
          <Grid container spacing={2} marginTop='0.5em' key={index}>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Time'
                select
                required
                aria-label='Time'
                onChange={(e) => {
                  const newLessons = [...lessons];
                  newLessons[index].lessonTime = e.target.value;
                  setLessons(newLessons);
                }}
                value={lesson.lessonTime ? lesson.lessonTime : ''}
                name='lessonTime'
                fullWidth
              >
                {lessonTimes.map((lessonTime) => {
                  return (
                    <MenuItem key={lessonTime.value} value={lessonTime.value}>
                      {lessonTime.display}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label='Type'
                select
                required
                aria-label='Type'
                onChange={(e) => {
                  const newLessons = [...lessons];
                  newLessons[index].lessonType = e.target.value as LessonType;
                  setLessons(newLessons);
                }}
                value={lesson.lessonType}
                name='lessonType'
                fullWidth
              >
                <MenuItem value='Flat'>Flat</MenuItem>
                <MenuItem value='Jumping'>Jumping</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label='Level'
                select
                required
                aria-label='Level'
                onChange={(e) => {
                  const newLessons = [...lessons];
                  newLessons[index].level = Number(e.target.value);
                  setLessons(newLessons);
                }}
                value={lesson.level !== undefined ? lesson.level : ''}
                name='level'
                fullWidth
              >
                <MenuItem value='0'>Novice</MenuItem>
                <MenuItem value='1'>1</MenuItem>
                <MenuItem value='2'>2</MenuItem>
                <MenuItem value='3'>3</MenuItem>
                <MenuItem value='4'>4</MenuItem>
                <MenuItem value='5'>5</MenuItem>
                <MenuItem value='6'>6+</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={1} alignContent={'center'}>
              <Button
                variant='contained'
                color='error'
                onClick={() => {
                  const newLessons = [...lessons];
                  newLessons.splice(index, 1);
                  setLessons(newLessons);
                }}
                fullWidth
                disabled={index === 0}
              >
                <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
              </Button>
            </Grid>
          </Grid>
        );
      })}
      <Grid container spacing={2} justifyContent='center' marginTop='0.5em'>
        <Grid item xs={8} marginBottom={'1em'}>
          <Button
            variant='contained'
            color='inherit'
            onClick={() => {
              let newLessons = [...lessons];
              newLessons.push({
                program: lessons[0].program,
                lessonType: '',
                startDate: lessons[0].startDate,
                endDate: lessons[0].endDate,
                activeDays: lessons[0].activeDays,
              });
              setLessons(newLessons);
            }}
            fullWidth
          >
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} />
            Add Lesson
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default HorsemanshipLessonData;
