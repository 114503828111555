import { RiderLesson } from '../../types/People';
import { displayRiderLevel, formatTime } from '../../helpers/utils';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Grid, Typography } from '@mui/material';

function DisplayRiderLessons(props: {
  title: string;
  riderLessons: RiderLesson[];
  buttonsDisabled: boolean;
  onDeleteClick?: Function;
  displayBlank?: boolean;
  displayDuration?: boolean;
}) {
  if (props.riderLessons.length <= 0) {
    if (props.displayBlank) {
      return (
        <>
          <Typography variant='h4' marginBottom={'1em'}>
            {props.title}
          </Typography>
          No lessons
        </>
      );
    }

    return null;
  }

  const colSize = !props.displayDuration ? 3 : 2;

  return (
    <>
      <Grid container marginTop='1em'>
        <Typography variant='h4' marginBottom={'1em'}>
          {props.title}
        </Typography>
      </Grid>
      {props.riderLessons.map((lesson, index) => {
        if (!lesson.lessonId || lesson.lessonId < 0) {
          return null;
        }

        return (
          <Box
            sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}
            padding={'1em'}
            key={index}
          >
            <Typography variant='h6' fontWeight={'bold'}>
              Lesson #{index + 1}
            </Typography>
            <Grid container spacing={2}>
              {props.displayDuration ? (
                <Grid item xs={12} sm={2}>
                  <Typography fontWeight={'bold'}>Lesson Duration</Typography>
                  <Typography>{`${lesson.lessonStartDate} to ${lesson.lessonEndDate}`}</Typography>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={colSize}>
                <Typography fontWeight={'bold'}>Lesson Day</Typography>
                <Typography>{lesson.lessonDay}</Typography>
              </Grid>
              <Grid item xs={12} sm={colSize}>
                <Typography fontWeight={'bold'}>Lesson Time</Typography>
                <Typography>{lesson.lessonTime ? formatTime(lesson.lessonTime) : ''}</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography fontWeight={'bold'}>Level</Typography>
                <Typography>{displayRiderLevel(lesson.level)}</Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography fontWeight={'bold'}>Left Class</Typography>
                <Typography>{lesson.leftClass ? lesson.leftClass : 'No'}</Typography>
              </Grid>
              <Grid item xs={12} sm={2} alignContent={'center'}>
                {props.onDeleteClick && !lesson.leftClass ? (
                  <Button
                    color='error'
                    variant='contained'
                    id={`delete-${lesson.lessonId}`}
                    onClick={() => {
                      if (props.onDeleteClick) {
                        props.onDeleteClick(lesson.lessonId?.toString() ?? '');
                      }
                    }}
                    disabled={props.buttonsDisabled}
                    fullWidth
                  >
                    <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </>
  );
}

export default DisplayRiderLessons;
