import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import { Lesson, LessonType, Program } from '../../types/Lessons';
import { SubmitButton } from '../../components/SubmitButton';
import { Container, Grid, MenuItem, TextField, Typography } from '@mui/material';
import HorsemanshipLessonData from './HorsemanshipLessonData';
import TypedLessonData from './TypedLessonData';
import UntypedLessonData from './UntypedLessonData';
import OtherLessons from './OtherLessons';
import { formatTime } from '../../helpers/utils';
import dayjs from 'dayjs';

const isLessonOverlap = (newLesson: Lesson, existingLesson: Lesson): boolean => {
  console.log(newLesson.lessonTime, existingLesson.lessonTime?.split(' ')[0]);
  if (newLesson.lessonTime != existingLesson.lessonTime?.split(' ')[0]) {
    return false;
  }

  const hasCommonActiveDay = newLesson.activeDays.some((day) =>
    existingLesson.activeDays.includes(day)
  );

  // If no common active day, there's no overlap
  if (!hasCommonActiveDay) {
    return false;
  }

  console.log('Lessons', newLesson, existingLesson);

  // Parse start and end dates with dayjs
  const newStart = dayjs(newLesson.startDate);
  const newEnd = dayjs(newLesson.endDate);
  const existingStart = dayjs(existingLesson.startDate);
  const existingEnd = dayjs(existingLesson.endDate);

  // Ensure the lesson times overlap (i.e., date ranges intersect)
  if (
    newStart.isBefore(existingEnd, 'day') &&
    newEnd.isAfter(existingStart, 'day') // Date overlap check
  ) {
    // If the lesson times overlap on a common active day
    return true;
  }

  return false;
};

const doesLessonOverlapWithExisting = (newLesson: Lesson, existingLessons: Lesson[]): boolean => {
  for (const existingLesson of existingLessons) {
    if (isLessonOverlap(newLesson, existingLesson)) {
      return true; // If any lesson overlaps
    }
  }
  return false; // No overlap
};

function AddLesson() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [existingLessons, setExistingLessons] = useState<Lesson[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([
    {
      program: '',
      lessonType: '',
      startDate: '',
      endDate: '',
      activeDays: [],
    },
  ]);

  let lessonTimes = [];
  for (let i = 0; i < 24; i++) {
    let hour = i;
    let morningNight = ' AM';
    if (i == 0) {
      hour = 12;
    } else if (i == 12) {
      morningNight = ' PM';
    } else if (i > 12) {
      hour = i - 12;
      morningNight = ' PM';
    }

    lessonTimes.push(
      { value: `${i}:00`, display: `${hour}:00${morningNight}` },
      { value: `${i}:30`, display: `${hour}:30${morningNight}` }
    );
  }

  const handleSubmit = async (event: any) => {
    setErrorMessage('');
    setLoading(true);

    let error = '';
    let overlappingLessons: string[] = [];
    lessons.forEach((lesson, index) => {
      console.log(lesson, existingLessons);
      if (!lesson.program) {
        error = 'Please select a program.';
      } else if (!lesson.startDate) {
        error = 'Please select a start date.';
      } else if (!lesson.endDate) {
        error = 'Please select an end date.';
      } else if (lesson.activeDays.length <= 0) {
        error = 'Please select a lesson day.';
      } else if (!lesson.lessonTime) {
        error = 'Please ensure all lessons have a lesson time selected.';
      } else if (!lesson.lessonType) {
        error = 'Please ensure all lessons have a lesson type selected.';
      } else if (
        lesson.program === 'Horsemanship' &&
        (lesson.level === undefined || lesson.level < 0)
      ) {
        error = 'Please ensure all lessons have a level selected.';
      } else if (doesLessonOverlapWithExisting(lesson, existingLessons)) {
        overlappingLessons.push(lesson.lessonTime);
      }
    });

    if (overlappingLessons.length > 0) {
      setErrorMessage(
        `Error creating lessons. The following lessons overlap with existing lessons:\n ${overlappingLessons.join(
          ', '
        )}.`
      );
      setLoading(false);
      return;
    }

    if (error) {
      setErrorMessage(error);
      setLoading(false);
      return;
    }

    try {
      const requests = lessons.map((lesson) => HttpClient.post('/lessons', lesson));

      const failedLessons: string[] = [];
      Promise.allSettled(requests).then((result) => {
        for (let i = 0; i < result.length; i++) {
          if (result[i].status == 'rejected') {
            failedLessons.push(`${lessons[i].activeDays.join(', ')} @ ${lessons[i].lessonTime}`);
          }
        }

        setErrorMessage(
          `Failed to add the following lessons: ${failedLessons.join(
            ', '
          )}. Please try again and contact Rebecca if the problem persists.`
        );
        setLoading(false);
        if (failedLessons.length === 0) {
          navigate('/lessons');
        }
      });
    } catch (error) {
      setErrorMessage((error as Error).message);
      setLoading(false);
    }
  };

  const getLessons = async () => {
    let queryParams = { amount: 'currentAndNext' };
    try {
      const result: Lesson[] = await HttpClient.get('/lessons', {}, queryParams);
      setExistingLessons(
        result.map((lesson) => {
          return {
            ...lesson,
            lessonTime: lesson.lessonTime ? formatTime(lesson.lessonTime) : '',
          };
        })
      );
    } catch (error) {
      setErrorMessage('Could not load available lessons. Please contact Rebecca.');
    }
  };

  useEffect(() => {
    getLessons();
  }, []);

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Container>
        <Grid item>
          <Typography variant='h3' gutterBottom align='center'>
            Add Lessons
          </Typography>
        </Grid>
      </Container>

      <ErrorBanner errorHeading='Error adding lesson' errorMessage={errorMessage} />

      <Container style={{ marginBottom: '1em' }}>
        <Typography variant='h4'>Basic Info</Typography>
        <Grid container spacing={2} marginTop='0.5em'>
          <Grid item xs={12} sm={12}>
            <TextField
              label='Program'
              select
              required
              aria-label='Program'
              onChange={(e) => {
                let lessonType: LessonType = '';
                if (e.target.value === 'Learn to Ride') {
                  lessonType = 'Flat';
                } else if (e.target.value === 'Mini/Jr') {
                  lessonType = 'On-Lead';
                }

                let newLessons = [
                  {
                    program: e.target.value as Program,
                    lessonType,
                    startDate: '',
                    endDate: '',
                    activeDays: [],
                  },
                ];
                setLessons(newLessons);
                console.log(newLessons);
              }}
              value={lessons[0].program}
              name='program'
              fullWidth
            >
              <MenuItem value='Horsemanship'>Horsemanship</MenuItem>
              <MenuItem value='Learn to Ride'>Learn to Ride</MenuItem>
              <MenuItem value='Mini/Jr'>Mini/Jr Riders</MenuItem>
              <MenuItem value='Summer Camp'>Camp</MenuItem>
              <MenuItem value='Birthday Party'>Birthday Party</MenuItem>
              <MenuItem value='Theme Ride'>Theme Ride</MenuItem>
              <MenuItem value='Other'>Other</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        {lessons[0].program === 'Horsemanship' && (
          <HorsemanshipLessonData
            lessons={lessons}
            setLessons={setLessons}
            existingLessons={existingLessons}
          />
        )}
        {(lessons[0].program === 'Learn to Ride' || lessons[0].program === 'Mini/Jr') && (
          <TypedLessonData
            lessons={lessons}
            setLessons={setLessons}
            lessonType={lessons[0].lessonType}
          />
        )}
        {(lessons[0].program === 'Summer Camp' ||
          lessons[0].program === 'Birthday Party' ||
          lessons[0].program === 'Theme Ride') && (
          <UntypedLessonData lessons={lessons} setLessons={setLessons} />
        )}
        {lessons[0].program === 'Other' && (
          <OtherLessons
            lesson={lessons[0]}
            setLesson={(lesson: Lesson) => {
              setLessons([lesson]);
            }}
          />
        )}
      </Container>
      <SubmitButton handleSubmit={handleSubmit} disabled={loading} />
    </Container>
  );
}

export default AddLesson;
