import { Lesson } from '../../types/Lessons';
import { UnformattedDailyUsage, Usage } from '../../types/Usage';
import { Person } from '../../types/People';
import { useEffect, useState } from 'react';
import DisplayHorsemanshipUsageRow from './DisplayHorsemanshipRow';

import AddSharpIcon from '@mui/icons-material/AddSharp';
import { HttpClient } from '../../helpers/httpClient';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Day } from '../../types/Day';

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function DisplayHorsemanshipUsage(props: {
  riders: Person[];
  lessons: Lesson[];
  selectedDate: string;
  selectedHorse: string;
  setErrorMessage: Function;
}) {
  const [usage, setUsage] = useState<UnformattedDailyUsage[]>([]);

  const { setErrorMessage, selectedHorse, selectedDate } = props;
  const dayOfWeek = dayString[new Date(selectedDate).getUTCDay()];

  function addUsage() {
    setUsage([
      ...usage,
      {
        usageType: 'G',
        usageDate: props.selectedDate,
        horse: props.selectedHorse,
        duration: 60,
        rider: '',
        firstName: '',
        lastName: '',
        lessonTime: '',
        lesson: '',
      },
    ]);
  }

  function updateUsage(usageId: number, updatedUsage: UnformattedDailyUsage) {
    const nextUsage = usage.map((usage: UnformattedDailyUsage) => {
      if (usageId === usage.id) {
        return updatedUsage;
      }

      return usage;
    });

    setUsage(nextUsage);
  }

  async function getUsage(
    setUsage: Function,
    setErrorMessage: Function,
    selectedDate: string,
    selectedHorse: string
  ) {
    try {
      const result: UnformattedDailyUsage[] = await HttpClient.get(
        `/usage/day?date=${encodeURIComponent(selectedDate)}&horse=${encodeURIComponent(
          selectedHorse
        )}&formatted=false`
      );

      setUsage(
        result.filter((usage: UnformattedDailyUsage) => {
          return (
            (usage.program === 'Horsemanship' ||
              usage.program === 'Learn to Ride' ||
              usage.program === 'Other') &&
            (usage.usageType === 'G' || usage.usageType === 'RA')
          );
        })
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getUsage(setUsage, setErrorMessage, selectedDate, selectedHorse);
  }, []);

  return (
    <Grid marginTop='1em'>
      <Typography variant='h5' fontWeight={'bold'} marginBottom='1em'>
        Horsemanship & LTR
      </Typography>
      {usage.map((usage: UnformattedDailyUsage, index: number) => {
        return (
          <Box
            sx={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white' }}
            padding={'0.5em'}
            key={usage.id}
          >
            <Typography variant='h6' fontWeight={'bold'}>
              Class #{index + 1}
            </Typography>
            <DisplayHorsemanshipUsageRow
              usage={usage}
              lessons={props.lessons.filter((lesson) => {
                return lesson.activeDays.includes(dayOfWeek as Day);
              })}
              riders={props.riders}
              setErrorMessage={setErrorMessage}
              getUsage={() => getUsage(setUsage, setErrorMessage, selectedDate, selectedHorse)}
              updateUsage={updateUsage}
            />
          </Box>
        );
      })}

      <Grid container marginBottom='1em' marginTop='1em' justifyContent='center'>
        <Grid item>
          <Button variant='contained' color='inherit' id='add-rider' onClick={addUsage}>
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} /> Add Rider
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DisplayHorsemanshipUsage;
