import { MenuItem, TextField } from '@mui/material';

function AddCoach(props: { coach: string | undefined; setCoach: Function; required?: boolean }) {
  return (
    <TextField
      label='Coach'
      select
      aria-label='Coach'
      onChange={(e) => {
        props.setCoach(e.target.value);
      }}
      value={props.coach ?? ''}
      name='coach'
      fullWidth
      required={props.required}
    >
      <MenuItem value='Alex'>Alex</MenuItem>
      <MenuItem value='Andrea'>Andrea</MenuItem>
      <MenuItem value='Christy'>Christy</MenuItem>
      <MenuItem value='Grace'>Grace</MenuItem>
      <MenuItem value='Lauryn'>Lauryn</MenuItem>
      <MenuItem value='Lynda'>Lynda</MenuItem>
      <MenuItem value='Maureen'>Maureen</MenuItem>
      <MenuItem value='Rebecca'>Rebecca</MenuItem>
      <MenuItem value='Ryn'>Ryn</MenuItem>
    </TextField>
  );
}

export default AddCoach;
