import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import ErrorBanner from '../../components/ErrorBanner';
import { Lesson } from '../../types/Lessons';
import { FormSubmitButton } from '../../components/SubmitButton';
import { Container, Grid, MenuItem, TextField, Typography } from '@mui/material';
import LessonDaysSelect from '../../components/Lessons/LessonDaysSelect';
import AddCoach from '../../components/Lessons/AddCoach';

function LessonData(props: { lesson: Lesson; setLesson: Function }) {
  const { lesson, setLesson } = props;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  let lessonTimes = [];
  for (let i = 0; i < 24; i++) {
    let hour = i;
    let morningNight = ' AM';
    if (i == 0) {
      hour = 12;
    } else if (i == 12) {
      morningNight = ' PM';
    } else if (i > 12) {
      hour = i - 12;
      morningNight = ' PM';
    }

    lessonTimes.push(
      { value: `${i}:00`, display: `${hour}:00${morningNight}` },
      { value: `${i}:30`, display: `${hour}:30${morningNight}` }
    );
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    let newValue: string | boolean | number = e.target.type === 'checkbox' ? checked : value;

    if (name === 'level') {
      newValue = Number(value);
    }

    setLesson((prevState: Lesson) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label='Program'
            select
            required
            aria-label='Program'
            onChange={handleInputChange}
            value={lesson.program}
            name='program'
            fullWidth
          >
            <MenuItem value='Horsemanship'>Horsemanship</MenuItem>
            <MenuItem value='Learn to Ride'>Learn to Ride</MenuItem>
            <MenuItem value='Summer Camp'>Camp</MenuItem>
            <MenuItem value='Little Bits'>Little Bits</MenuItem>
            <MenuItem value='Other'>Other</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label='Type'
            select
            required
            aria-label='Type'
            onChange={handleInputChange}
            value={lesson.lessonType}
            name='lessonType'
            fullWidth
          >
            <MenuItem value='Flat'>Flat</MenuItem>
            <MenuItem value='Jumping'>Jumping</MenuItem>
            <MenuItem value='On-Lead'>On-Lead</MenuItem>
            <MenuItem value='Unmounted'>Unmounted</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label='Level'
            select
            required
            aria-label='Level'
            onChange={handleInputChange}
            value={lesson.level !== undefined ? lesson.level : ''}
            name='level'
            fullWidth
          >
            <MenuItem value='-1'></MenuItem>
            <MenuItem value='0'>Novice</MenuItem>
            <MenuItem value='1'>1</MenuItem>
            <MenuItem value='2'>2</MenuItem>
            <MenuItem value='3'>3</MenuItem>
            <MenuItem value='4'>4</MenuItem>
            <MenuItem value='5'>5</MenuItem>
            <MenuItem value='6'>6+</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop='1em'>
        <Grid item xs={12} sm={6}>
          <TextField
            label='Start Date'
            required
            type='date'
            onChange={handleInputChange}
            value={lesson.startDate}
            name='startDate'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label='End Date'
            required
            type='date'
            onChange={handleInputChange}
            value={lesson.endDate}
            name='endDate'
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop='1em'>
        <Grid item xs={12} sm={4}>
          <LessonDaysSelect
            required
            selectedItems={lesson.activeDays}
            setSelectedItems={(e) => {
              setLesson({
                ...lesson,
                activeDays: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label='Time'
            select
            required
            aria-label='Time'
            onChange={handleInputChange}
            value={lesson.lessonTime ? lesson.lessonTime : ''}
            name='lessonTime'
            fullWidth
          >
            {lessonTimes.map((lessonTime) => {
              return (
                <MenuItem key={lessonTime.value} value={lessonTime.value}>
                  {lessonTime.display}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <AddCoach
            coach={lesson.coach ? lesson.coach : ''}
            setCoach={(coach: string) => {
              setLesson({
                ...lesson,
                coach,
              });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LessonData;
