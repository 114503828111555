export const TIME_SUFFIX = 'T12:00:00Z'

export function formatTime(time: string | undefined) {
  if (!time) {
    return '';
  }

  let baseTime = time.split('T')[1].slice(0, 5);
  let [hours, mins] = baseTime.split(':');

  const hoursNumber = Number(hours);
  if (hoursNumber === 0 || hoursNumber === 12) {
    return `12:${mins} ${hoursNumber === 0 ? 'AM': 'PM'}`;
  }

  if (hoursNumber < 12) {
    return `${hoursNumber}:${mins} AM`;
  }

  return `${hoursNumber % 12}:${mins} PM`;
}

export function formatUsageTime(duration: number, divisor: number = 60) {
  return Math.round((duration / divisor) * 100) / 100;
}

export function dateToString(date: Date | undefined | null) {
  return date ? `${date.getUTCFullYear()}-${('0' + (date.getUTCMonth() + 1)).slice(-2)}-${(
    '0' + date.getUTCDate()
  ).slice(-2)}` : '';
}


export function dateToStringLocal(date: Date | undefined | null) {
  return date ? `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
    '0' + date.getDate()
  ).slice(-2)}` : '';
}

export function concatIfBothNotEmpty(str1: string, str2: string) {
  if (str1.trim() !== "" && str2.trim() !== "") {
      return str1 + ", " + str2;
  } else if (str1) {
    return str1
  }

  return str2; 
}

export function displayRiderLevel(level?: number) {

  if (level === undefined || level < 0) {
    return 'N/A'
  } else if (level === 0){
    return 'Novice'
  }

  return level.toString()
}

export function displayRiderName(firstName: string | undefined, lastName: string | undefined) {
  if (!firstName) {
    return ""
  }

  if (!lastName) {
    return firstName
  }

  return `${firstName} ${lastName.charAt(0).toUpperCase()}.`
}