import { Horse } from '../../types/Horses';
import { AddedRider, Person, RiderList } from '../../types/People';
import HorseSelect from '../HorseSelect';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import DeleteIcon from '@mui/icons-material/Delete';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { HttpClient } from '../../helpers/httpClient';
import { useNavigate } from 'react-router-dom';
import { formatTime } from '../../helpers/utils';
import { v4 as uuidv4 } from 'uuid';
import { Lesson, LessonModifications } from '../../types/Lessons';
import ConfirmHorsemanshipSubmitModal from '../Modals/ConfirmHorsemanshipSubmitModal';
import { SubmitButton } from '../SubmitButton';
import dayjs from 'dayjs';

import {
  Autocomplete,
  Button,
  Grid,
  MenuItem,
  TextField,
  Checkbox,
  Typography,
  Box,
} from '@mui/material';
import { Day } from '../../types/Day';

type KeyValue = {
  [key: string]: string;
};

type KeyBoolean = {
  [key: string]: boolean;
};

type KeyArray = {
  [key: string]: string[];
};

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function getDuplicates(obj: KeyArray) {
  let dupl: string[] = [];
  let horses: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key].length > 1) {
      horses.push(key);
      obj[key].forEach((item) => {
        dupl.push(item);
      });
    }
  });
  return { duplicates: dupl, horses };
}

function AddHorsemanshipUsage(props: {
  modifications: LessonModifications[];
  riders: Person[];
  horses: Horse[];
  setErrorMessage: Function;
  date: dayjs.Dayjs;
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [currentRidersForDay, setCurrentRidersForDay] = useState<RiderList[]>([]);

  const currentRiders = currentRidersForDay.map((filteredRider, index) => {
    return {
      ...filteredRider,
      rowId: String(index),
    };
  });

  const lessonTimes = Array.from(
    new Set(
      currentRiders.map((rider) => {
        return rider.lessonTime;
      })
    )
  );

  const evalRiders = props.riders.filter((rider) => {
    return rider.eval;
  });

  const schoolingRiders = props.riders.filter((rider) => {
    return rider.schooling;
  });

  const allH = props.riders.filter((rider) => {
    return !rider.eval;
  });

  let allLessons = lessons.map((lesson) => {
    return { lesson: lesson.lessonTime, id: lesson.id };
  });

  let riders: KeyValue = {};
  let absentRiders: KeyBoolean = {};
  for (let i = 0; i < currentRiders.length; i++) {
    riders[currentRiders[i].rowId] = '';
  }
  for (let j = 0; j < props.modifications.length; j++) {
    if (props.modifications[j].modificationType === 'Absence') {
      absentRiders[props.modifications[j].rider] = true;
    } else {
      currentRiders.push({
        rowId: '',
        id: props.modifications[j].rider,
        firstName: props.modifications[j].riderFirstName,
        lastName: props.modifications[j].riderLastName,
        lessonTime: props.modifications[j].lessonTime,
        rider: true,
        staff: false,
        eval: false,
        schooling: false,
        volunteer: false,
        volunteerLevel: 0,
      });
    }
  }

  currentRiders.sort((a, b) => {
    return a.lessonTime! > b.lessonTime! ? 1 : -1;
  });

  const [riderHorse, setRiderHorse] = useState(riders);
  const [riderAbsent, setRiderAbsent] = useState(absentRiders);
  const [errors, setErrors] = useState<string[]>([]);
  const [addedRiders, setAddedRiders] = useState<AddedRider[]>([]);
  const [currentUsage, setCurrentUsage] = useState<any[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [missingRiders, setMissingRiders] = useState<string[]>([]);

  function isSchoolingRider(id: string) {
    for (let i = 0; i < addedRiders.length; i++) {
      if (addedRiders[i].addedId === id && addedRiders[i].schooling) {
        return true;
      }
    }

    return false;
  }

  async function handleSubmit() {
    setLoading(true);
    let currentLesson = -1;
    let currentLessonHorses: KeyArray = {};
    let usage = [];
    let errors: string[] = [];
    let missingHorse: string[] = [];
    let horseErrors: string[] = [];
    let absentErrors: string[] = [];

    // Need to verify that all added riders were added to a lesson first because
    // we use the lessonId to check duplicates so it can't be invalid
    const missingThings = addedRiders.filter(
      (rider) => !rider.lessonId || rider.lessonId < 0 || !rider.horse || !rider.id
    );

    if (missingThings.length > 0) {
      missingThings.forEach((thing) => {
        errors.push(thing.addedId);
      });
      setErrors(errors);
      props.setErrorMessage('All added riders must have a lesson, rider and horse.');
      setLoading(false);
      return;
    }

    const allRiderHorses = {
      ...riderHorse,
    };
    addedRiders.forEach((rider) => {
      allRiderHorses[rider.addedId] = rider.horse;
    });

    const mappedRiders = currentRiders.map((rider) => {
      return { lessonId: rider.lessonId, id: rider.id, added: false, rowId: rider.rowId };
    });

    const mappedAddedRiders = addedRiders.map((rider) => {
      return { lessonId: rider.lessonId, id: rider.id, added: true, rowId: rider.addedId };
    });

    const allRiders = mappedRiders.concat(mappedAddedRiders);
    allRiders.sort((r1, r2) => {
      return r1.lessonId! - r2.lessonId!;
    });

    for (let i = 0; i < allRiders.length; i++) {
      if (allRiders[i].lessonId !== currentLesson) {
        const { duplicates, horses } = getDuplicates(currentLessonHorses);
        if (duplicates) {
          horses.forEach((item) => {
            for (let i = 0; i < props.horses.length; i++) {
              if (item === props.horses[i].id) {
                horseErrors.push(props.horses[i].barnName);
              }
            }
          });
          duplicates.forEach((item) => {
            errors.push(item);
          });
        }

        currentLesson = allRiders[i].lessonId!;
        currentLessonHorses = {};
      }

      const currentHorse = allRiderHorses[allRiders[i].rowId];
      if (!currentHorse) {
        errors.push(allRiders[i].rowId);
        missingHorse.push(allRiders[i].rowId);

        if (riderAbsent[allRiders[i].rowId]) {
          absentErrors.push(allRiders[i].rowId);
        }
        continue;
      } else {
        if (!currentLessonHorses[currentHorse]) {
          currentLessonHorses[currentHorse] = [];
        }
        currentLessonHorses[currentHorse].push(allRiders[i].rowId);
      }

      usage.push({
        usageType: riderAbsent[allRiders[i].rowId]
          ? 'RA'
          : allRiders[i].added && isSchoolingRider(allRiders[i].rowId)
          ? 'S'
          : 'G',
        rider: allRiders[i].id,
        lesson: allRiders[i].lessonId,
        horse: currentHorse,
        duration: 60,
        usageDate: props.date.format('YYYY-MM-DD'),
      });
    }

    setCurrentUsage(usage);
    if (horseErrors.length > 0) {
      props.setErrorMessage(
        `The following horses are being ridden by multiple people in the same class: ${horseErrors.join(
          ', '
        )}`
      );
      setErrors(errors);
      setLoading(false);
      return;
    }

    if (absentErrors.length > 0) {
      props.setErrorMessage(
        'Riders are absent and missing an assigned horse. Please assign them a horse to submit the usage.'
      );
      setErrors(absentErrors);
      setLoading(false);
      return;
    }

    if (errors.length > 0) {
      setModalOpen(true);
      setMissingRiders(
        missingHorse.map((rowId) => {
          const rider = currentRiders.find((rider) => {
            return rider.rowId === rowId;
          });

          if (!rider) {
            return '';
          }

          return `${rider?.firstName} ${rider.lastName} (${
            rider.lessonTime ? formatTime(rider.lessonTime) : ''
          })`;
        })
      );

      setLoading(false);
      return;
    }

    await handleConfirmSubmit(usage);
    setLoading(false);
  }

  async function handleConfirmSubmit(usage?: any) {
    setLoading(true);
    if (!usage) {
      usage = currentUsage;
    }

    if (usage.length === 0) {
      props.setErrorMessage('There is no usage to enter for this day.');
      setLoading(false);
      return;
    }

    try {
      const result = await HttpClient.post('/usage', usage);
      console.log(result);
      navigate(`/usage/${props.date.format('YYYY-MM-DD')}`);
    } catch (error) {
      props.setErrorMessage((error as Error).message);
    }

    setLoading(false);
  }

  function handleHorseChange(e: ChangeEvent<HTMLInputElement>, riderId?: string) {
    if (riderId) {
      const updated = {
        ...riderHorse,
      };
      updated[riderId] = e.target.value;
      setRiderHorse(updated);
    }
  }

  function handleAbsentChange(checked: boolean, rowId?: string) {
    if (rowId) {
      const updated = {
        ...riderAbsent,
      };
      updated[rowId] = checked;
      setRiderAbsent(updated);
    }
  }

  function handleAddedRiderChange(addedId: string, newRider: AddedRider) {
    const nextRiders = addedRiders.map((rider) => {
      if (rider.addedId === addedId) {
        return {
          ...rider,
          id: newRider.id,
          firstName: newRider.firstName,
          lastName: newRider.lastName,
        };
      }

      return rider;
    });

    setAddedRiders(nextRiders);
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>, index?: string) {
    let value: string | number = e.target.value;
    if (e.target.name === 'lessonId') {
      value = Number(value);
    }
    if (index) {
      const nextRiders = addedRiders.map((rider, i) => {
        if (String(i) === index) {
          return {
            ...rider,
            [e.target.name]: value,
          };
        }

        return rider;
      });

      setAddedRiders(nextRiders);
    }
  }

  function addSchoolingRider() {
    setAddedRiders([
      ...addedRiders,
      {
        addedId: uuidv4(),
        id: '',
        lessonId: -1,
        horse: '',
        schooling: true,
        firstName: '',
        lastName: '',
      },
    ]);
  }

  function addRider() {
    setAddedRiders([
      ...addedRiders,
      {
        addedId: uuidv4(),
        id: '',
        lessonId: -1,
        horse: '',
        schooling: false,
        firstName: '',
        lastName: '',
      },
    ]);
  }

  function removeAddedRider(event: MouseEvent<HTMLButtonElement>) {
    let node: HTMLInputElement | null = event.target as HTMLInputElement;
    let count = 4;
    while (node && !node.value && count > 0) {
      node = node.parentElement as HTMLInputElement;
      count--;
    }

    let buttonIndex = node.value;
    const newRiders = addedRiders.filter((rider, index) => {
      return String(index) !== buttonIndex;
    });
    setAddedRiders(newRiders);
  }

  async function getLessons(setLessons: Function, setErrorMessage: Function, date: dayjs.Dayjs) {
    try {
      const result: Lesson[] = await HttpClient.get(`/lessons?date=${date.format('YYYY-MM-DD')}`);
      setLessons(
        result.filter((lesson) => lesson.activeDays.includes(dayString[date.day()] as Day))
      );
    } catch (error) {
      setErrorMessage('Could not load available lessons. Please contact Rebecca.');
    }
  }

  async function getCurrentRiders(
    setRiders: Function,
    setErrorMessage: Function,
    date: dayjs.Dayjs
  ) {
    try {
      const result = await HttpClient.get(
        `/riders/day/${dayString[date.day()]}?date=${date.format('YYYY-MM-DD')}`
      );
      console.log(result);
      setRiders(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  useEffect(() => {
    getLessons(setLessons, props.setErrorMessage, props.date);
    getCurrentRiders(setCurrentRidersForDay, props.setErrorMessage, props.date);
  }, [props.date, props.setErrorMessage]);

  return (
    <div>
      <ConfirmHorsemanshipSubmitModal
        missingRiderHorse={missingRiders}
        show={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleConfirm={handleConfirmSubmit}
      />
      <Box>
        {lessonTimes.map((lessonTime, index) => (
          <Box key={index}>
            <Typography
              variant='h6'
              sx={{
                fontWeight: 'bold',
                paddingTop: '1em',
              }}
            >
              {formatTime(lessonTime)} Class
            </Typography>

            <Grid
              container
              item
              columnSpacing={2}
              paddingTop='0.5em'
              paddingBottom='0.5em'
              marginLeft='-0.5em'
            >
              <Grid item xs={4} sx={{ fontWeight: 'bold' }}>
                Name
              </Grid>
              <Grid item xs={5} sx={{ fontWeight: 'bold' }}>
                Horse
              </Grid>
              <Grid item xs={3} sx={{ fontWeight: 'bold' }}>
                Absent
              </Grid>
            </Grid>

            {currentRiders.map((rider, index) => {
              if (rider.lessonTime !== lessonTime) {
                return null;
              }

              return (
                <Grid
                  item
                  container
                  key={index}
                  id={rider.rowId}
                  sx={{
                    backgroundColor: errors.includes(rider.rowId)
                      ? 'rgb(248, 215, 218)'
                      : index % 2 === 0
                      ? '#f5f5f5'
                      : 'white',
                  }}
                  alignItems='center'
                  justifyContent='center'
                  columnSpacing={2}
                  paddingTop='1em'
                  paddingBottom='1em'
                  marginLeft='-0.5em'
                >
                  <Grid item xs={4}>
                    {rider.firstName + ' ' + rider.lastName}
                  </Grid>
                  <Grid item xs={5}>
                    <HorseSelect
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleHorseChange(event, rider.rowId)
                      }
                      horses={props.horses}
                    />
                  </Grid>
                  <Grid item xs={3} justifyContent={'center'} alignContent={'center'}>
                    <Checkbox
                      checked={riderAbsent[rider.rowId] ?? false}
                      onChange={(event: any) => {
                        handleAbsentChange(event.target.checked, rider.rowId);
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        ))}
      </Box>
      <Box>
        {addedRiders.length > 0 ? (
          <Grid item xs={12}>
            <Typography
              variant='h6'
              sx={{
                fontWeight: 'bold',
                paddingTop: '1em',
              }}
            >
              Added Riders
            </Typography>
          </Grid>
        ) : null}
        {addedRiders.map((rider, index) => {
          return (
            <Box key={index}>
              <Grid
                container
                columnSpacing={2}
                sx={{
                  backgroundColor: errors.includes(rider.addedId)
                    ? 'rgb(248, 215, 218)'
                    : (index + currentRiders.length) % 2 === 0
                    ? '#f5f5f5'
                    : 'white',
                }}
                marginLeft='-0.5em'
                paddingRight='1em'
              >
                <Grid item xs={12}>
                  <Typography
                    variant='body1'
                    sx={{
                      fontWeight: 'bold',
                      marginTop: '0.5em',
                    }}
                  >
                    Added Rider #{index + 1}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                columnSpacing={2}
                rowSpacing={1}
                id={`${index}-${rider.addedId}`}
                sx={{
                  backgroundColor: errors.includes(rider.addedId)
                    ? 'rgb(248, 215, 218)'
                    : (index + currentRiders.length) % 2 === 0
                    ? '#f5f5f5'
                    : 'white',
                }}
                paddingTop='0.5em'
                paddingBottom='1em'
                marginLeft='-0.5em'
                paddingRight='1em'
              >
                <Grid item xs={12} sm={3}>
                  <TextField
                    label='Lesson Time'
                    select
                    required
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(event, String(index))
                    }
                    value={rider.lessonId > 0 ? rider.lessonId : ''}
                    name='lessonId'
                    fullWidth
                  >
                    {allLessons.map((lesson) => {
                      return (
                        <MenuItem key={lesson.id} value={lesson.id}>
                          {formatTime(lesson.lesson!)}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                    getOptionKey={(option) => option.id}
                    value={rider.id ? rider : null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={allH.map((hRider) => {
                      return {
                        addedId: rider.addedId,
                        id: hRider.id,
                        lessonId: rider.lessonId,
                        schooling: rider.schooling,
                        horse: '',
                        firstName: hRider.firstName,
                        lastName: hRider.lastName,
                      };
                    })}
                    renderInput={(params) => <TextField {...params} label='Rider' />}
                    onChange={(event, selectedOption) => {
                      handleAddedRiderChange(
                        rider.addedId,
                        selectedOption ?? {
                          addedId: rider.addedId,
                          id: '',
                          lessonId: -1,
                          horse: '',
                          schooling: rider.schooling,
                          firstName: '',
                          lastName: '',
                        }
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <HorseSelect
                    horses={props.horses}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      handleInputChange(event, String(index))
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={3} justifyContent={'center'} alignContent={'center'}>
                  <Button
                    variant='contained'
                    color='error'
                    id={`delete-${index}`}
                    value={index}
                    onClick={removeAddedRider}
                    fullWidth
                  >
                    <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
      <Grid
        container
        spacing={2}
        marginTop={'1em'}
        marginBottom={'1em'}
        justifyContent='center'
        alignItems='center'
      >
        <Grid item sm={6}>
          <Button
            variant='contained'
            color='inherit'
            id='add-schooling-rider'
            onClick={addSchoolingRider}
            fullWidth
          >
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} /> Add Schooling Rider
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            variant='contained'
            color='inherit'
            id='add-horsemanship-rider'
            onClick={addRider}
            fullWidth
          >
            <AddSharpIcon style={{ width: '20', paddingBottom: '3.5px' }} /> Add Rider
          </Button>
        </Grid>
      </Grid>
      <SubmitButton handleSubmit={handleSubmit} disabled={loading} />
    </div>
  );
}

export default AddHorsemanshipUsage;
