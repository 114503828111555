import React, { ReactNode, useState } from 'react';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';

function MultiSelectDropdown(props: {
  options: { display: string; value: string }[];
  label: string;
  selectedItems: string[];
  setSelectedItems: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
  required?: boolean;
}) {
  const { options, label, selectedItems, setSelectedItems, required } = props;

  return (
    <FormControl fullWidth>
      <InputLabel id='multiple-select-label'>{`${label} ${required ? '*' : ''}`}</InputLabel>
      <Select
        required
        label={label}
        id={`multiple-select-${label}`}
        multiple
        value={selectedItems}
        onChange={setSelectedItems}
        renderValue={(selected) => selected.join(', ')}
      >
        {options.map((item, index) => {
          return (
            <MenuItem value={item.value} key={index}>
              <Checkbox checked={selectedItems.indexOf(item.value) > -1} />
              <ListItemText primary={item.value} />
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>
        {selectedItems.length === 0 ? 'Select at least one item' : ''}
      </FormHelperText>
    </FormControl>
  );
}

export default MultiSelectDropdown;
