import { ReactNode } from 'react';
import { SelectChangeEvent } from '@mui/material';
import MultiSelectDropdown from '../MultipleSelect';

function LessonDaysSelect(props: {
  required?: boolean;
  selectedItems: string[];
  setSelectedItems: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
}) {
  const { required, selectedItems, setSelectedItems } = props;

  return (
    <MultiSelectDropdown
      options={[
        { display: 'Sunday', value: 'Sunday' },
        { display: 'Monday', value: 'Monday' },
        { display: 'Tuesday', value: 'Tuesday' },
        { display: 'Wednesday', value: 'Wednesday' },
        { display: 'Thursday', value: 'Thursday' },
        { display: 'Friday', value: 'Friday' },
        { display: 'Saturday', value: 'Saturday' },
      ]}
      label='Lesson Days'
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      required={required}
    />
  );
}

export default LessonDaysSelect;
